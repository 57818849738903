const React = require('react');
const {
  GlobalContextProvider,
} = require('./src/context/GlobalContextProvider');

// TODO Pathnames on route change might be useful
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname);
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null);
// };

exports.wrapPageElement = ({ element, props }) => {
  return <GlobalContextProvider {...props}>{element}</GlobalContextProvider>;
};
