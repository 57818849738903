import React, { createContext, useReducer } from 'react';
import { ShoppingCartProvider } from './ShoppingCartContext';
type GlobalContextProviderProps = {
  children: React.ReactNode;
};

type InitialStateType = {
  authenticated: boolean;
};

type ActionType = {
  type: string;
};

const initialState: InitialStateType = {
  authenticated: !!(
    typeof window !== 'undefined' && localStorage?.getItem('AUTH_TOKEN')
  ),
};

export const GlobalStateContext = createContext<{ state: InitialStateType }>({
  state: initialState,
});
export const GlobalDispatchContext = createContext<{
  dispatch: React.Dispatch<ActionType>;
}>({ dispatch: () => null });

function reducer(state: InitialStateType, action: ActionType) {
  switch (action.type) {
    case 'SIGN_IN': {
      return {
        ...state,
        authenticated: true,
      };
    }
    case 'SIGN_OUT': {
      return {
        ...state,
        authenticated: false,
      };
    }
    default:
      throw new Error('Bad Action Type');
  }
}

export const GlobalContextProvider = ({
  children,
}: GlobalContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state }}>
      <GlobalDispatchContext.Provider value={{ dispatch }}>
        <ShoppingCartProvider>{children}</ShoppingCartProvider>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};
